import React, {useState, useRef} from 'react'
import {  PhoneIcon } from '@heroicons/react/outline'


const Form = (props) => {
  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbzhfbxNPOHtZW9YFvfrYYcHf93gWKYQ-D2QOiFbDKHPv91V-IP4wSMc5lv7YhdjiAw2gw/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) =>{
      e.preventDefault()
      setLoading(true)

      fetch(scriptUrl, {
      method: 'POST', 
      body: new FormData(formRef.current),

  }).then(res => {
          //console.log("SUCCESSFULLY SUBMITTED")
          window.location.assign('/devis/contact/merci/')
          setLoading(false)
      })
      .catch(err => console.log(err))
  }

  return (
    <form 
        className="mt-3 sm:flex"
        name="google-sheet"
        ref={formRef} onSubmit={handleSubmit}
    >
        <label htmlFor="tel" className="sr-only">
            Téléphone
            </label>
            <input
                type="text"
                name="Nom"
                id="nom"
                className="mb-2 lg:mb-0 block w-full mr-3 py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:flex-1 border-orange-600"
                placeholder="Nom"
                required
            />
            <input
                type="number"
                name="telephone"
                id="tel"
                className="mb-2 lg:mb-0 block w-full mr-3 py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:flex-1 border-orange-600"
                placeholder="Votre numéro"
                required
            />
        <div className="hidden">
            <label htmlFor="tellepro" className="sr-only">
            Téllépro
            </label>
            <input
                type="text"
                name="tellepro"
            />
            <input
                type="text"
                name="Source"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                placeholder="Source"
                value="blicko.fr"
            />
            <input
                type="text"
                name="Moyen"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                value={props.moyen}
            />
            <input
                type="text"
                name="IdForm"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                value={props.destinataire}
            />
        </div>
            <button
            type="submit"
            className="inline-flex items-center rounded-md ring-1 bg-orange-600 hover:bg-orange-700 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
            <PhoneIcon className="flex h-6 w-6 mr-2" aria-hidden="true" />
            {loading ? "Envoi en cours ..." : "Rappel immédiat"}
            </button>
    </form>
  );
}

export default Form