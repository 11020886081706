/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// const posts = [
//   {
//     id: 1,
//     title: 'Boost your conversion rate',
//     href: '#',
//     description:
//       'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
//     imageUrl:
//       'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
//     date: 'Mar 16, 2020',
//     datetime: '2020-03-16',
//     category: { title: 'Marketing', href: '#' },
//     author: {
//       name: 'Michael Foster',
//       role: 'Co-Founder / CTO',
//       href: '#',
//       imageUrl:
//         'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//   },
//   // More posts...
// ]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Un problème de plomberie ?</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
          Nous vous aidons à trouver une solution et vous envoyons un devis gratuitement en toute transparence à distance avant d’envisager un déplacement. 
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <article className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <StaticImage
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  src="../../images/landing/devis.jpg"
                  alt="Devis"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <span className="absolute inset-0" />
                      Devis gratuit
                  </h3>
                  <p className="mt-5 line-clamp-3 text-lg leading-6 text-gray-600">
                  Nous vous fournissons un devis gratuit pour tous les travaux nécessaires.
                  </p>
                </div>
              </div>
            </article>

            <article className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <StaticImage
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  src="../../images/landing/247.jpg"
                  alt="24/7"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <span className="absolute inset-0" />
                      Disponible 24/7
                  </h3>
                  <p className="mt-5 line-clamp-3 text-lg leading-6 text-gray-600">
                  Nous sommes à votre service 24h/24, 7j/7, pour tous vos problèmes de plomberie.
                  </p>
                </div>
              </div>
            </article>
            
            <article className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <StaticImage
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  src="../../images/landing/transparence.jpg"
                  alt="Transparence"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <span className="absolute inset-0" />
                      Transparence 
                  </h3>
                  <p className="mt-5 line-clamp-3 text-lg leading-6 text-gray-600">
                  Tarifs forfaitaires annoncés à l'avance pour éviter toute surprise. 
                  </p>
                </div>
              </div>
            </article>
        </div>
      </div>
    </div>
  )
}
