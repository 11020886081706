import React from "react"
import { Helmet } from "react-helmet"

import KdpHeader from "../components/headers/headerLandingFg"
import KdpSection1 from "../components/landing-fg/section1"
import KdpSection4 from "../components/landing-fg/section4"
import KdpSection5 from "../components/landing-fg/section5"
import KdpSectionAvis from "../components/homev2/sectionAvis"
import KdpFooter from "../components/footers/footerv2"

export default function Index() {
    return (
      <div className="bg-white">
        <Helmet>
          <title>Votre Plombier à Lyon et dans le Rhône - blicko.fr</title>
          <meta name="description" content="Réalisez des économies avec blicko | Un problème de plomberie ? Montrez-le nous par visio ! Avant d'envisager un déplacement inutile, nos plombiers vous aide à distance." />
        </Helmet>
        <header className="sticky top-0 z-50">
          <KdpHeader />
        </header>
        <main className="relative">
  
          <KdpSection1 />
          <KdpSection5 />
          <KdpSection4 />
          {/* <KdpSection2 /> */}
          <KdpSectionAvis />
  
        </main>
  
        <footer>
          <KdpFooter />
        </footer>
      </div>
    );
  }